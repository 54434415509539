import * as React from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useRemoveDjangoApp } from '../hooks/useRemoveDjangoApp';
import { DJANGO_PAGES_PATHS } from '../constants/djangoPagesRoutes';

function RemoveDjangoApp() {
  const location = useLocation();

  const isDjangoPage = React.useMemo(() => {
    return matchPath(location.pathname, DJANGO_PAGES_PATHS) !== null;
  }, [location.pathname]);

  const handleRemoveDjangoApp = useRemoveDjangoApp();

  React.useEffect(() => {
    if (!isDjangoPage) {
      handleRemoveDjangoApp();
    }
  }, [handleRemoveDjangoApp, isDjangoPage]);

  return null;
}

export { RemoveDjangoApp };
